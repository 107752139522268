<template>
    <div class="container">
        <poster :poster-list="posterList" :show-time="false" :page-path="pagePath" :module-key="moduleKey" :token="token"/>
    </div>
</template>

<script>
    import Poster from '../../../components/Poster/Index'

    export default {
        name: "NewYearPoster",
        components: {
            Poster
        },
        data() {
            return {
                token: this.$route.query.token,
                posterList: [
                    {
                        backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423175431896.png',
                        tempBgImage: null,
                        miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423175949596.png',
                        textColor: '#fff4cb',
                        tempPoster: '',
                        textPoint: [480, 1166],
                        wxCode: {width: 220, point: [39, 1071]}
                    },
                    {
                        backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210425150615781.png',
                        tempBgImage: null,
                        miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423180003626.png',
                        textColor: '#fff4cb',
                        tempPoster: '',
                        textPoint: [480, 1166],
                        wxCode: {width: 220, point: [39, 1071]}
                    },
                ],
                pagePath: this.baseHost + '/',
                moduleKey: ''
            }
        },
        created() {
            this.moduleKey = this.token ? '558afbea-e82c-4f7e-a31e-c85473283b5f' : 'gzhDHmzXM20210423'
        }
    }
</script>

<style scoped>

</style>