<template>
  <div class="poster" v-if="posterList.length > 0">
    <canvas id="myCanvas" ref="myCanvas" :style="
        'top:' +
        width +
        'px;left:' +
        height +
        'px;width: ' +
        width +
        'px;height:' +
        height +
        'px;'
      " />
    <div class="show-image" :style="posterList.length <= 1 ? 'bottom:0' : ''">
      <div>
        <img :src="posterImg" class="image-object" />
        <div class="down-img" @click="downImg" v-if="isInApp && posterImg">
          保存图片到相册
        </div>
      </div>
    </div>
    <div class="footer-tab" v-if="posterList.length > 1">
      <div v-for="(item, index) in posterList" :key="index" :class="tabIndex == index ? 'active' : ''" @click="changeBg(index)">
        <div>
          <img :src="item.miniImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deliveryMessage, replaceAppToken } from "../../utils/appUtil";
import { formatTimeArr, formatTimeStr } from "../../utils/formatTime";

export default {
  name: "Index",
  props: {
    posterList: {
      type: Array,
      default: [],
    },
    token: {
      type: String,
      default: "",
    },
    timeFormat: {
      type: Number,
      default: 1,
    },
    pagePath: "",
    moduleKey: "",
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      width: 750,
      height: 1334,
      tabIndex: 0,
      posterImg: "",
      qrCode: "",
      actTime: "",
      isInApp: this.token && this.token !== "0",
    };
  },
  created() {
    this.downloadMaterial();
    if (this.isInApp) {
      replaceAppToken(this.token, () => {
        this.initFun();
        this.getAppActiveTime();
      });
    } else {
      this.initFun();
      this.getActiveTime();
    }
  },
  mounted() {
    this.$toast.loading({
      overlay: true,
      message: "海报生成中...",
      duration: 0,
    });

    this.beforeDraw();
  },
  methods: {
    initFun() {
      this.generateQrCode();
    },
    //下载素材
    downloadMaterial() {
      let list = this.posterList || [];
      list.forEach((item) => {
        let img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        this.getImageBlob(item.backgroundImage, (res) => {
          img.src = res;
          item.tempBgImage = img;
        });
      });
    },
    getImageBlob(url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status === 200) {
          if (cb) cb(URL.createObjectURL(this.response));
        }
      };
      xhr.send();
    },
    changeBg: function (index) {
      this.tabIndex = index;
      this.beforeDraw();
    },
    beforeDraw() {
      let item = this.posterList[this.tabIndex];
      if (this.qrCode && this.actTime && item.tempBgImage) {
        this.draw(item);
      } else {
        setTimeout(() => {
          this.beforeDraw();
        }, 100);
      }
    },
    draw(item) {
      let width = this.width;
      let height = this.height;
      let canvas = this.$refs.myCanvas;
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(item.tempBgImage, 0, 0, width, height); //背景图片
      let wxCodeData = item.wxCode;
      ctx.drawImage(
        this.qrCode,
        wxCodeData.point[0],
        wxCodeData.point[1],
        wxCodeData.width,
        wxCodeData.width
      );
      let fontSize = item.textFontSize ? item.textFontSize : 28;
      ctx.font = fontSize + "px HelveticaNeue";
      ctx.fillStyle = item.textColor;
      let textPoint = item.textPoint;
      if (textPoint.length > 0 && this.showTime) {
        ctx.fillText(this.actTime, textPoint[0], textPoint[1]); // 1158
      }
      item.tempPoster = canvas.toDataURL("image/png");
      this.posterImg = item.tempPoster;
      this.$toast.clear();
    },
    downImg: function () {
      let message = { action: "downImg", data: { url: this.posterImg } };
      deliveryMessage(message);
    },
    generateQrCode: function () {
      let that = this;
      let path = "";
      let appPath =
        this.baseHost +
        "/7999/Redesign/html/applyStore/appDownload.html?appStore=";
      path = this.isInApp ? appPath : this.pagePath;
      this.post("/base/ToolsC/QrCode?", {
        size: 2,
        url: path,
      }).then((json) => {
        if (json.code === 1) {
          let img = new Image();
          img.src = json.response;
          img.onload = () => {
            this.qrCode = img;
          };
        } else {
          console.log("走没走这里");
        }
      });
    },
    getActiveTime: function () {
      //公众号 活动控制
      this.post("/Base/Tools/ModuleIsShowList", {
        moduleKEY: this.moduleKey,
      }).then((json) => {
        if (json.code === 1) {
          let data = json.response || [];
          for (let i = 0; i < data.length; i++) {
            let modelKey = data[i].ModuleKEY;
            if (modelKey === this.moduleKey) {
              let time = data[i].model.showTimeStart;
              if ([1, 2].indexOf(this.timeFormat) >= 0) {
                let tempTime = "";
                if (this.timeFormat === 2) {
                  tempTime += "活动时间：";
                }
                return (this.actTime = tempTime + time.split(" ")[0] + "起");
              }
              let date = new Date(formatTimeStr(time));
              let arr = formatTimeArr(date);

              let time_end = data[i].model.showTimeEnd;
              let date_end = new Date(formatTimeStr(time_end));
              let arr_end = formatTimeArr(date_end);
              arr.forEach((item, index) => {
                item = item.replace(/\b(0+)/gi, "");
                arr.splice(index, 1, item);
              });
              arr_end.forEach((item, index) => {
                item = item.replace(/\b(0+)/gi, "");
                arr_end.splice(index, 1, item);
              });
              let startTimeStr = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";
              if (this.timeFormat === 3) return (this.actTime = startTimeStr + "起");
              if (this.timeFormat === 4) return (this.actTime = startTimeStr + "开始");
              this.actTime = arr[1] + "月" + arr[2] + "日" + "-" + arr_end[1] + "月" + arr_end[2] + "日";
            }
          }
        }
      });
    },
    getAppActiveTime: function () {
      //App 活动控制
      this.post("/Base/Tools/GetAPPModuleList", {
        moduleKEY: this.moduleKey,
      }).then((json) => {
        if (json.code === 1) {
          let data = json.response || [];
          for (let i = 0; i < data.length; i++) {
            let modelKey = data[i].ModuleKEY;
            if (modelKey === this.moduleKey) {
              let time = data[i].model.begintime_show;
              if (this.timeFormat === 2)
                return (this.actTime =
                  "活动时间：" + time.split(" ")[0] + "起");
              let date = new Date(formatTimeStr(time));
              let arr = formatTimeArr(date);
              if (this.timeFormat === 3)
                return (this.actTime =
                  arr[0] + "年" + arr[1] + "月" + arr[2] + "日" + "起");
              this.actTime = arr[0] + "-" + arr[1] + "-" + arr[2];
            }
          }
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.poster {
  canvas {
    position: fixed;
  }

  .show-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);

    .image-object {
      max-width: 70%;
      border-radius: 12px;
    }

    .down-img {
      width: 50%;
      height: 35px;
      background: white;
      margin-top: 25px;
      border-radius: 5px;
      text-align: center;
      line-height: 35px;
      font-size: 14px;
      display: inline-block;
    }
  }
}

.footer-tab {
  left: 0;
  right: 0;
  padding: 0 10px;
  position: absolute;
  height: 70px;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    border-radius: 8px;
    width: 62px;
    height: 62px;
    margin: 0 2.5px;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.active {
      background: #999999;
    }

    > div {
      border-radius: 8px;
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin: auto;

      img {
        border-radius: 8px;
        width: 50px;
      }
    }
  }
}
</style>