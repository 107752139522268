var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.posterList.length > 0)?_c('div',{staticClass:"poster"},[_c('canvas',{ref:"myCanvas",style:('top:' +
      _vm.width +
      'px;left:' +
      _vm.height +
      'px;width: ' +
      _vm.width +
      'px;height:' +
      _vm.height +
      'px;'),attrs:{"id":"myCanvas"}}),_c('div',{staticClass:"show-image",style:(_vm.posterList.length <= 1 ? 'bottom:0' : '')},[_c('div',[_c('img',{staticClass:"image-object",attrs:{"src":_vm.posterImg}}),(_vm.isInApp && _vm.posterImg)?_c('div',{staticClass:"down-img",on:{"click":_vm.downImg}},[_vm._v(" 保存图片到相册 ")]):_vm._e()])]),(_vm.posterList.length > 1)?_c('div',{staticClass:"footer-tab"},_vm._l((_vm.posterList),function(item,index){return _c('div',{key:index,class:_vm.tabIndex == index ? 'active' : '',on:{"click":function($event){return _vm.changeBg(index)}}},[_c('div',[_c('img',{attrs:{"src":item.miniImage}})])])}),0):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }